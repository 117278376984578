import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import {Button} from 'gatsby-theme-material-ui'

import Layout from '../components/layout'
import { Typography, Box, Container, Paper, Grid } from '@material-ui/core'

const TecajeviPage = ({ data }) => {
    
  const image = data.allFile.nodes[0]
  return (
    <Layout fixed title="Prevođenje">
        
      <Grid container spacing={4} alignItems="center" justifyItems='center'>
        
        <Grid item md={6}>
          
        <Typography paragraph variant="h3" color="primary" align="left">
        Prijevodi
        </Typography><br/>
          <Typography paragraph variant="body1">
            <p>U našoj ponudi nudimo prevođenje sa svih jezika na hrvatski jezik, kao i s hrvatskog jezika na strane
jezike. Cijena se obračunava po broju kartica.</p><p> Prevoditeljska kartica sastoji se od 1500 znakova s
razmacima.</p><p> Tekstove nam možete poslati i mailom, u skeniranom obliku na mail: <a href="mailto:gloria@bj.t-com.hr">gloria@bj.t-com.hr</a></p>
            <br/>
            
            
          </Typography>
          
          <Grid container spacing={3}>
              <Grid item>
                <Button to="/cjenik#prijevodi" color="secondary" variant="contained">Cjenik prevođenja</Button>
              </Grid>
              <Grid item>
                <Button to="/kontakt" color="primary" variant="contained">Kontaktirajte nas!</Button>
              </Grid>
              
          </Grid>
          
            

        </Grid>

        
            <Grid item  md={6} xs={8} >
              <Img
        fluid={image.childImageSharp.fluid}
        //style={{height:"60%"}}
      />
            </Grid>

              
      </Grid>
        

    </Layout>
  )
}
export const query = graphql`

    query {
        allFile(filter: { relativePath: { eq: "gloria/polica_4.jpg" } }) {
          nodes {
            childImageSharp {
              fluid(
                maxWidth: 1200
                srcSetBreakpoints: [200, 340, 480, 900]
                quality: 80
              ) {
                ...GatsbyImageSharpFluid
                originalName
                aspectRatio
              }
            }
          }
        }
      }

`

export default TecajeviPage
